import { DeviceType } from '@ridi/event-client';
import UAParser from 'ua-parser-js';

const paperRegExp = /PAPER/;
const inappRegExp = /ridibooks/;

export const getIsPWA = () => window.matchMedia('(display-mode: standalone)').matches;

export const getDeviceType = (userAgent: string): DeviceType => {
  if (paperRegExp.test(userAgent)) {
    return DeviceType.PAPER;
  }
  if (inappRegExp.test(userAgent)) {
    return DeviceType.APP;
  }
  const { type } = new UAParser(userAgent).getDevice();

  const isPWA = getIsPWA();

  if (type === 'mobile') {
    return isPWA ? DeviceType.MOBILE_PWA : DeviceType.MOBILE;
  }

  return isPWA ? DeviceType.PC_PWA : DeviceType.PC;
};
