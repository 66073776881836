export type FeatureFlagDurationValue = {
  startAt: string;
  endAt: string;
};

export const isEnabled = <T extends FeatureFlagDurationValue>(duration?: T) => {
  if (!duration || !duration.startAt || !duration.endAt) {
    return false;
  }

  const now = new Date().getTime();
  return now >= new Date(duration.startAt).getTime() && now <= new Date(duration.endAt).getTime();
};
