import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import type { ActionRequest, RootState } from '@/features/store';
import { BooksDescriptions } from '@/models/bookApi/BooksDescriptions/BooksDescriptionsType';
import { SearchResult } from '@/models/searchApi/Search/SearchType';
import { fetchBooks } from '@/services/bookApi/books/booksService';
import { fetchBooksDescriptions } from '@/services/bookApi/descriptions/booksDescriptionsService';
import { SearchRequest, SearchRequestQuery } from '@/services/searchApi/search/interfaces/SearchRequest';
import { search } from '@/services/searchApi/search/searchService';
import { searchBookDataUtils } from '@/utils/bookData';
import { BookApiBookData, SearchBookData } from '@/utils/bookData/types';
import { encodeSearchAPIRequestQuery, SearchQuery } from '@/utils/search';

interface FullSearchRequest {
  query: SearchRequestQuery;
  timestamp: number;
}

interface MappedSearchResult {
  author: SearchResult['author'];
  book: Omit<SearchResult['book'], 'books'> & { books: SearchBookData[] };
}

export interface FullSearchState {
  isLoading: boolean;
  isBookLoading: boolean;
  isCategoryLoading: boolean;
  lastRequest?: FullSearchRequest;
  result?: MappedSearchResult;
}

interface FullSearchActionPayload {
  result: MappedSearchResult;
  request: FullSearchRequest;
}

export const fullSearchSelector = (state: RootState): FullSearchState => state.search.fullSearch;

const setLoadingAction = createAction<{ all: true } | { all: false; book: boolean; category: boolean }>(
  'search/fullSearch/setLoadingAction',
);

// 검색 결과에서 작가를 빈 배열로 설정함
// 책 Placeholder만 보여줘야할 경우에 사용
const setAuthorsEmptyAction = createAction('search/fullSearch/setAuthorsEmptyAction');

export const fullSearchAction = createAsyncThunk<
  FullSearchActionPayload,
  ActionRequest<SearchQuery>,
  {
    state: RootState;
  }
>('search/fullSearch/fullSearchAction', async ({ req, reqParams }, thunkAPI) => {
  const requestTimestamp = Date.now();
  const query = {
    query: encodeSearchAPIRequestQuery(reqParams),
  } as SearchRequest;

  const isPublisherQuery = reqParams.keyword.startsWith('출판사:');
  if (isPublisherQuery) {
    query.query.keyword = reqParams.keyword.replace('출판사:', '');
  }

  const { lastRequest, result: lastResult } = thunkAPI.getState().search.fullSearch;
  const isUpdateQuery = lastRequest?.query.keyword === query.query.keyword && lastResult;
  const categoryShouldUpdate =
    lastRequest?.query.adult_exclude !== query.query.adult_exclude || lastRequest?.query.rent !== query.query.rent;

  if (isUpdateQuery || isPublisherQuery) {
    // 작가는 놔두고 책만 다시 불러옴
    query.query.what = 'base';
    query.query.where = ['book'];

    if (isPublisherQuery) {
      // 출판사 검색
      query.query.what = 'publisher';
      thunkAPI.dispatch(setAuthorsEmptyAction());
    }

    thunkAPI.dispatch(setLoadingAction({ all: false, book: true, category: categoryShouldUpdate }));
  } else {
    // 기본 검색
    query.query.what = 'base';
    query.query.where = ['book', 'author'];
    thunkAPI.dispatch(setLoadingAction({ all: true }));
  }

  const pendingRequest = {
    timestamp: requestTimestamp,
    query: query.query,
  };

  const [error, model] = await search(query, req);

  if (error) {
    return thunkAPI.rejectWithValue({
      message: error.message,
      data: error.response?.data,
      timestamp: requestTimestamp,
    });
  }

  const { result } = model;
  if (isUpdateQuery && lastResult) {
    result.author = lastResult.author;
  }

  const bookIds = result.book.books.map(book => book.b_id).join(',');
  let mappedBooks: SearchBookData[] = [];

  if (bookIds.length > 0) {
    const [[bookError, bookApiBooks], [descriptionError, bookApiDescriptions]] = await Promise.all([
      fetchBooks({ query: { b_ids: bookIds } }),
      fetchBooksDescriptions({ query: { b_ids: bookIds } }),
    ]);

    if (bookError || descriptionError) {
      const bookApiError = bookError || descriptionError;

      return thunkAPI.rejectWithValue({
        message: bookApiError.message,
        data: bookApiError.response?.data,
        timestamp: requestTimestamp,
      });
    }

    const bookByBookIds = bookApiBooks.Data.reduce(
      (books, book) => ({ ...books, [book.id]: book }),
      Object.create(null) as Record<string, BookApiBookData>,
    );

    const descriptionByBookIds = bookApiDescriptions.Data.reduce(
      (books, description) => ({ ...books, [description.b_id]: description.descriptions }),
      Object.create(null) as Record<string, BooksDescriptions>,
    );

    mappedBooks = result.book.books
      .filter(searchBook => !(bookByBookIds[searchBook.b_id] ?? { is_deleted: true }).is_deleted)
      .map(searchBook => {
        const book = bookByBookIds[searchBook.b_id];
        const description = descriptionByBookIds[searchBook.b_id];
        const searchBookData = searchBookDataUtils.convertFromBookApiBookData(book, searchBook);
        searchBookData.description = description;

        return searchBookData;
      });
  }

  return {
    result: {
      author: result.author,
      book: {
        ...result.book,
        books: mappedBooks,
      },
    },
    request: pendingRequest,
  };
});

export const fullSearchSlice = createSlice({
  name: 'search/fullSearch',

  initialState: {
    isLoading: true,
    isCategoryLoading: true,
    isBookLoading: true,
  } as FullSearchState,

  reducers: {},

  extraReducers: builder => {
    builder.addCase(setAuthorsEmptyAction, (state, _) => {
      if (state.result) {
        state.result.author = { authors: [], total: 0 };
      }
    });

    builder.addCase(setLoadingAction, (state, action) => {
      state.isLoading = state.isLoading || action.payload.all;
      state.isBookLoading = state.isBookLoading || action.payload.all || action.payload.book;
      state.isCategoryLoading = state.isCategoryLoading || action.payload.all || action.payload.category;
    });

    builder.addCase(fullSearchAction.fulfilled, (state, action) => {
      const { request, result } = action.payload;

      if (state.lastRequest && state.lastRequest.timestamp > request.timestamp) {
        return;
      }

      state.lastRequest = request;
      state.result = result;
      state.isLoading = false;
      state.isBookLoading = false;
      state.isCategoryLoading = false;
    });
  },
});
