import { debug } from '@/utils/debug';

import type { EventClientType } from '..';
import { RIDIEventClient } from './RIDIEventClient';

const log = debug('TestEventClient');

export const TestEventClient: EventClientType =
  typeof Proxy === 'undefined'
    ? RIDIEventClient
    : new Proxy(RIDIEventClient, {
        get(_target, key) {
          if (key === 'isInitialized') {
            return () => true;
          }

          return (...args: Any[]) => log(`call__${String(key)}`, JSON.stringify(args));
        },
      });
