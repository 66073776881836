import { AccountActionChangePasswordModel } from '@/models/booksBackend/ChangePasswordModel/AccountActionChangePasswordModel';
import { ServiceMethod, stringifyRequestParams } from '@/services/baseService';

import { makeCallAPIMethod } from '../booksBackendService';
import { AccountActionChangePasswordRequest } from './interfaces/AccountActionChangePasswordRequest';

const call = makeCallAPIMethod(AccountActionChangePasswordModel);

export const accountActionChangePassword: ServiceMethod<
  AccountActionChangePasswordRequest,
  AccountActionChangePasswordModel
> = async reqParams =>
  call<AccountActionChangePasswordRequest, Error, AccountActionChangePasswordModel>({
    method: 'POST',
    pathname: '/account/action/change-password',
    preferNonProxied: true,
    params: { body: reqParams.body },
    transformRequest: [stringifyRequestParams],
  });
