import { BookApiBookData, BookRenderData } from '../types';

export const getTitle = (book: BookApiBookData): string => {
  if (book.is_deleted) {
    return '';
  }
  if (book.series) {
    return book.series.property.title || book.title.main;
  }
  return book.title.main;
};

export const getThumbnailId = (book: BookApiBookData): string => {
  const category = book.categories[0]?.genre || 'general';
  if (book.series) {
    if (
      category !== 'general' &&
      !book.series.property.is_completed &&
      book.series.property.opened_last_volume_id.length !== 0
    ) {
      return book.series.property.opened_last_volume_id;
    }
  }

  return book.id;
};

export const getGenre = (book: BookApiBookData): string =>
  book.categories?.find(category => category?.sub_genre)?.sub_genre ?? '';

export const getDiscountBadge = (book: BookApiBookData): number => {
  if (getGenre(book) === 'fantasy') {
    return 0;
  }

  const singleBuyDiscountRate = book.price_info.buy?.discount_percentage || 0;
  const singleRentDiscountRate = book.price_info.rent?.discount_percentage || 0;
  const seriesBuyDiscountRate = book.series?.property.is_serial
    ? book.series.price_info?.buy?.discount_percentage || 0
    : 0;
  const seriesRentDiscountRate = book.series?.property.is_serial
    ? book.series.price_info?.rent?.discount_percentage || 0
    : 0;

  const filteredDiscountRate = [
    singleBuyDiscountRate,
    singleRentDiscountRate,
    seriesBuyDiscountRate,
    seriesRentDiscountRate,
  ].filter(rate => rate < 100);

  const maxRate = Math.round(Math.max(...filteredDiscountRate));
  if (maxRate >= 10) {
    return maxRate;
  }

  return 0;
};

export const getRentalBadge = (book: BookApiBookData): boolean =>
  (!!book.price_info?.rent || (!!book.series?.price_info?.rent && book.series?.property?.is_serial)) &&
  ['general', 'romance', 'bl'].includes(getGenre(book));

export const getMaxFreeBook = (book: BookApiBookData): number =>
  Math.max(
    ...[book.series?.price_info?.buy?.free_book_count ?? 0, book.series?.price_info?.rent?.free_book_count ?? 0],
  );

export const getIsSeriesCategory = (book: BookApiBookData): boolean =>
  !!book.categories.find(category => category.is_series_category);

export const getSeries = (book: BookApiBookData): { total: number; unit: string; completion: boolean } | undefined => {
  if (!getIsSeriesCategory(book) || !book.series?.property) {
    return undefined;
  }

  return {
    total: book.series.property.opened_book_count,
    unit: book.series.property.unit ?? '권',
    completion: book.series.property.is_serial_complete,
  };
};

export const convertToRenderData = (book: BookApiBookData): BookRenderData => ({
  bookId: book.id,
  title: book.title?.main,
  cover: {
    badges: {
      discount: getDiscountBadge(book),
      rental: getRentalBadge(book),
      waitFree: book.series?.property?.is_wait_free,
    },

    freeBook: {
      count: getMaxFreeBook(book),
      unit: book.series?.property?.unit ?? '화',
    },

    setBook: {
      count: book.setbook?.member_books_count,
    },

    isAdultOnly: book.property?.is_adult_only,

    thumbnail: { thumbnailId: getThumbnailId(book) },
  },
  metadata: {
    title: {
      showTitle: true,
    },

    authors: book.authors,

    extra: {
      publisher: book.publisher?.name,
      series: getSeries(book),
    },
  },
});
