import { FeatureFlagModel } from '@/models/backendsApi/v2/FeatureFlag/FeatureFlagModel';
import { ServiceMethod } from '@/services/baseService';

import { makeLegacyCallAPIMethod } from '../../backendsApiService';

const call = makeLegacyCallAPIMethod(FeatureFlagModel);

type FeatureFlagError = {
  success: false;
  message: string;
  data: null;
};

export const featureFlag: ServiceMethod<void, FeatureFlagModel, FeatureFlagError> = async (_, req) =>
  call<void, FeatureFlagError, FeatureFlagModel>(
    {
      method: 'GET',
      pathname: '/v2/feature-flag',
      preferNonProxied: true,
    },
    req,
  );
