import { ScheduleEventsModel } from '@/models/booksBackend/ScheduleEvents/ScheduleEventsModel';
import { ServiceMethod } from '@/services/baseService';

import { makeCallAPIMethod } from '../booksBackendService';

const call = makeCallAPIMethod(ScheduleEventsModel);

export const scheduleEvents: ServiceMethod<void, ScheduleEventsModel, void> = async (_, req) =>
  call<void, void, ScheduleEventsModel>(
    {
      method: 'GET',
      pathname: '/api/schedule/events',
      preferNonProxied: true,
      timeout: 5000,
    },
    req,
  );
