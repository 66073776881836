import { PaymentRouteGetFreePassCouponAvailableBooksModel } from '@/models/booksBackend/PaymentRouteGetFreePassCouponAvailableBooks/PaymentRouteGetFreePassCouponAvailableBooksModel';
import { ServiceMethod, stringifyRequestParams } from '@/services/baseService';

import { makeCallAPIMethod } from '../booksBackendService';
import { PaymentRouteGetFreePassCouponAvailableBooksRequest } from './interfaces/PaymentRouteGetFreePassCouponAvailableBooksRequest';

const call = makeCallAPIMethod(PaymentRouteGetFreePassCouponAvailableBooksModel);

export interface PaymentRouteGetFreePassCouponAvailableBooksError {
  message: string;
}

export const paymentRouteGetFreePassCouponAvailableBooks: ServiceMethod<
  PaymentRouteGetFreePassCouponAvailableBooksRequest,
  PaymentRouteGetFreePassCouponAvailableBooksModel,
  PaymentRouteGetFreePassCouponAvailableBooksError
> = async (reqParams, req) =>
  call<void, PaymentRouteGetFreePassCouponAvailableBooksError, PaymentRouteGetFreePassCouponAvailableBooksModel>(
    {
      method: 'POST',
      pathname: '/api/payment/route/get-free-pass-coupon-available-books',
      params: { body: reqParams.body },
      transformRequest: [stringifyRequestParams],
      through: {
        method: 'POST',
        pathname: '/api/payment/route/get-free-pass-coupon-available-books',
        params: { body: reqParams.body },
      },
    },
    req,
  );
