import qs from 'qs';

import { PaymentRouteMethodModel } from '@/models/booksBackend/PaymentRouteMethod/PaymentRouteMethodModel';
import { ServiceMethod, stringifyRequestParams } from '@/services/baseService';

import { makeCallAPIMethod } from '../booksBackendService';
import { PaymentRouteMethodRequest } from './interfaces/PaymentRouteMethodRequest';

const call = makeCallAPIMethod(PaymentRouteMethodModel);

export const paymentRouteMethod: ServiceMethod<PaymentRouteMethodRequest, PaymentRouteMethodModel, void> = async (
  reqParams,
  req,
) => {
  const { method, endpoint, params } = reqParams.body;
  const apiURL = new URL(endpoint);

  const parsedParams = qs.parse(params);
  const methodParams =
    method === 'GET'
      ? {
          query: parsedParams,
        }
      : {
          body: parsedParams,
        };

  return call<PaymentRouteMethodRequest, void, PaymentRouteMethodModel>(
    {
      method,
      pathname: apiURL.pathname.replace(/^(?:\/api\/payment)?/, '/api/payment'),
      params: methodParams,
      transformRequest: [stringifyRequestParams],
      paramsSerializer: stringifyRequestParams,

      through: {
        method: 'POST',
        pathname: '/api/payment/route/method',
        params: reqParams,
      },
    },
    req,
  );
};
