import { createAction, createAsyncThunk, createSelector, createSlice, SerializedError } from '@reduxjs/toolkit';

import { ServerRequest } from '@/base/interfaces/ServerRequest';
import { FEATURE_FLAG_KEYS, getFeatureFlagValueByKey } from '@/components/common/FeatureFlag';
import { hydrateAction } from '@/features/hydrate';
import type { ActionRequest, RootState } from '@/features/store';
import type { Navigation } from '@/models/backendsApi/v2/Navigation/NavigationType';
import { fetchNavigation, NavigationError } from '@/services/backendsApi/v2/navigation/navigationService';
import {
  addToVisitedNavigationIds,
  decodeVisitedNavigationIds,
  findCurrentGlobalNavigation,
  findCurrentNavigationRoute,
  getNonExternalNavigationSubset,
  iterateAllNavigations,
} from '@/utils/navigation';
import type { RequestError } from '@/utils/request';

export type NavigationState = {
  navigation: Navigation | null;
  currentPath: string | null;
  visitedNavigationIds: number[] | null;
};

export const navigationStateSelector = (state: RootState): NavigationState =>
  state.global.globalNavigationBar.navigation;

export const rootNavigationSelector = createSelector(
  navigationStateSelector,
  navigationState => navigationState.navigation,
);

export const globalNavigationSelector = createSelector(
  rootNavigationSelector,
  rootNavigation => rootNavigation?.children,
);

export const genreChangeSheetSelector = createSelector(
  rootNavigationSelector,
  rootNavigation => rootNavigation?.genre_change_sheet?.web,
);

export const allNavigationsSelector = createSelector(
  rootNavigationSelector,
  rootNavigation => rootNavigation && iterateAllNavigations(rootNavigation),
);

export const currentPathSelector = createSelector(
  navigationStateSelector,
  navigationState => navigationState.currentPath,
);

export const currentNavigationRouteSelector = createSelector(
  rootNavigationSelector,
  currentPathSelector,
  (rootNavigation, currentPath) =>
    currentPath === null ? null : rootNavigation && findCurrentNavigationRoute(currentPath, rootNavigation),
);

export const currentGlobalNavigationSelector = createSelector(
  globalNavigationSelector,
  currentPathSelector,
  (globalNavigation, currentPath) =>
    currentPath === null ? null : findCurrentGlobalNavigation(currentPath, globalNavigation),
);

export const visitedNavigationIdsSelector = createSelector(
  navigationStateSelector,
  navigationState => navigationState.visitedNavigationIds && new Set(navigationState.visitedNavigationIds),
);

export const isVisitedNavigationIdsUpdatedSelector = createSelector(
  currentNavigationRouteSelector,
  visitedNavigationIdsSelector,
  (currentNavigationRoute, visitedNavigationIds) => {
    if (!(currentNavigationRoute && visitedNavigationIds)) {
      return false;
    }

    return currentNavigationRoute.every(navigation => visitedNavigationIds.has(navigation.id));
  },
);

const setCurrentPathAction = createAction<string>('global/globalNavigationBar/navigation/setCurrentPathAction');

const fetchNavigationAction = createAsyncThunk<
  Navigation,
  ActionRequest<void>,
  {
    rejectValue: RequestError<NavigationError>;
  }
>('global/globalNavigationBar/navigation/fetchNavigationAction', async ({ req }, thunkAPI) => {
  const [error, model] = await fetchNavigation(undefined, req);

  if (error) {
    return thunkAPI.rejectWithValue(error);
  }

  return getNonExternalNavigationSubset(model.Data.data.navigation);
});

export const addVisitedNavigationIdsAction = createAsyncThunk<number[] | null, void, { state: RootState }>(
  'global/globalNavigationBar/navigation/addVisitedNavigationIdsAction',
  async (_, thunkAPI) => {
    const allNavigations = allNavigationsSelector(thunkAPI.getState());
    const currentNavigationRoute = currentNavigationRouteSelector(thunkAPI.getState());
    const visitedNavigationIds = visitedNavigationIdsSelector(thunkAPI.getState());

    if (allNavigations === null || currentNavigationRoute === null || visitedNavigationIds === null) {
      return null;
    }

    const isVisitedNavigationIdsUpdated = isVisitedNavigationIdsUpdatedSelector(thunkAPI.getState());
    if (isVisitedNavigationIdsUpdated) {
      return null;
    }

    const existingNavigationIds = new Set(allNavigations.map(navigation => navigation.id));
    const updatedVisitedNavigationIds = Array.from(
      addToVisitedNavigationIds(visitedNavigationIds, currentNavigationRoute),
    );
    return updatedVisitedNavigationIds.filter(navigationId => existingNavigationIds.has(navigationId));
  },
);

export const updateVisitedNavigationIdsAction = createAction<
  (visitedNavigationIdsCookie?: string) => { payload: number[] }
>('global/globalNavigationBar/navigation/updateVisitedNavigationIdsAction', (visitedNavigationIdsCookie = '') => {
  if (!visitedNavigationIdsCookie) {
    return { payload: [] };
  }

  const visitedNavigationIds = Array.from(decodeVisitedNavigationIds(visitedNavigationIdsCookie));
  return { payload: visitedNavigationIds };
});

export const initNavigationAction = createAsyncThunk<
  void,
  { req: ServerRequest | null; currentPath?: string },
  {
    rejectValue: RequestError<NavigationError> | (SerializedError & { response: undefined });
  }
>('global/globalNavigationBar/navigation/initNavigationAction', async ({ req, currentPath }, thunkAPI) => {
  let fetchNavigationActionResult;
  if (
    getFeatureFlagValueByKey({
      featureFlag: req?.FeatureFlag,
      featureFlagKey: FEATURE_FLAG_KEYS.WEB_COOKIE_IN_NAVIGATION_20240318,
    })
  ) {
    fetchNavigationActionResult = await thunkAPI
      .dispatch(fetchNavigationAction({ req: req ?? undefined }))
      .catch(() => thunkAPI.dispatch(fetchNavigationAction({})));
  } else {
    fetchNavigationActionResult = await thunkAPI.dispatch(fetchNavigationAction({}));
  }

  if (fetchNavigationAction.rejected.match(fetchNavigationActionResult)) {
    const { payload, error } = fetchNavigationActionResult;
    return thunkAPI.rejectWithValue(payload ?? { ...error, response: undefined });
  }

  if (req) {
    thunkAPI.dispatch(updateVisitedNavigationIdsAction(req.cookies.ridi_nav));
  }

  if (currentPath !== undefined) {
    thunkAPI.dispatch(setCurrentPathAction(currentPath));
  }

  return undefined;
});

export const navigationSlice = createSlice<
  NavigationState,
  Record<string, Any>,
  'global/globalNavigationBar/navigation'
>({
  name: 'global/globalNavigationBar/navigation',
  initialState: {
    navigation: null,
    currentPath: null,
    visitedNavigationIds: null,
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchNavigationAction.fulfilled, (state, action) => {
      state.navigation = action.payload;
    });

    builder.addCase(addVisitedNavigationIdsAction.fulfilled, (state, action) => {
      if (action.payload) {
        state.visitedNavigationIds = action.payload;
      }
    });

    builder.addCase(updateVisitedNavigationIdsAction, (state, action) => {
      state.visitedNavigationIds = action.payload;
    });

    builder.addCase(setCurrentPathAction, (state, action) => {
      state.currentPath = action.payload;
    });

    builder.addCase(hydrateAction, (state, action) => navigationStateSelector(action.payload) || state);
  },
});
