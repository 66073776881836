import { createAction, createSlice } from '@reduxjs/toolkit';

import { hydrateAction } from '@/features/hydrate';
import type { RootState } from '@/features/store';
import { FeatureFlagType } from '@/models/backendsApi/v2/FeatureFlag/FeatureFlagType';

export type CheckoutFormFeatures = 'adult_verify';

export interface CheckoutFormState {
  needsOpenBook: {
    purchase: boolean;
    rental: boolean;
    defaultByApp: boolean;
  };
  features: CheckoutFormFeatures[];
  featureFlags: FeatureFlagType;
}

const initialState: CheckoutFormState = {
  needsOpenBook: {
    purchase: false,
    rental: false,
    defaultByApp: false,
  },
  features: [],
  featureFlags: [],
};

export const checkoutFormInitializeAction = createAction<{
  openBookDefault: boolean;
  features: CheckoutFormFeatures[];
  featureFlags: FeatureFlagType;
}>('inapp/checkout/checkoutForm/checkoutFormInitializeAction');

export const setNeedsOpenBookAction = createAction<{ type: 'purchase' | 'rental'; value: boolean }>(
  'inapp/checkout/checkoutForm/setNeedsOpenBookAction',
);

export const needsOpenBookSelector = (state: RootState): CheckoutFormState['needsOpenBook'] =>
  state.inapp.checkout.checkoutForm.needsOpenBook;

export const implementedFeaturesSelector = (state: RootState): CheckoutFormFeatures[] =>
  state.inapp.checkout.checkoutForm.features;

export const featureFlagsSelector = (state: RootState): FeatureFlagType =>
  state.inapp.checkout.checkoutForm.featureFlags;

export const checkoutFormSlice = createSlice({
  name: 'inapp/checkout/checkoutForm',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(hydrateAction, (state, action) => {
        const checkoutState = action.payload.inapp.checkout.checkoutForm;
        return { ...state, ...checkoutState };
      })
      .addCase(checkoutFormInitializeAction, (state, action) => {
        const { openBookDefault, features, featureFlags } = action.payload;
        state.needsOpenBook.defaultByApp = openBookDefault;
        state.features = features;
        state.featureFlags = featureFlags;
      })
      .addCase(setNeedsOpenBookAction, (state, action) => {
        const { type, value } = action.payload;

        state.needsOpenBook[type] = value;
      });
  },
});
