import { ProlongPasswordExpirationModel } from '@/models/booksBackend/ProlongPasswordExpirationModel/ProlongPasswordExpirationModel';
import { ServiceMethod } from '@/services/baseService';

import { makeCallAPIMethod } from '../booksBackendService';
import { ProlongPasswordExpirationRequest } from './interfaces/ProlongPasswordExpirationRequest';

const call = makeCallAPIMethod(ProlongPasswordExpirationModel);

export interface ProlongPasswordExpirationError extends Error {
  status?: number;
  message: string;
}

export const prolongPasswordExpiration: ServiceMethod<
  ProlongPasswordExpirationRequest,
  ProlongPasswordExpirationModel
> = async () =>
  call<ProlongPasswordExpirationRequest, ProlongPasswordExpirationError, ProlongPasswordExpirationModel>({
    method: 'POST',
    pathname: '/account/action/prolong-password-expiration',
    preferNonProxied: true,
  });
