import { SearchBook } from '@/models/searchApi/Search/SearchType';
import { stripAllTags } from '@/utils/sanitize';

import * as bookApiBookDataUtils from '../bookApiBookData';
import {
  BookApiBookData,
  BookRenderData,
  SearchBookData,
  SearchBookDataPrices,
  SearchBookDataSeriesPrices,
} from '../types';

export const getDescription = ({ description }: SearchBookData, targetLength: number): string => {
  if (!description) {
    return '';
  }

  const descriptionEntries = [
    { value: description.intro },
    { value: description.publisher_review, prefix: '출판사 서평' },
    { value: description.author, prefix: '저자 소개' },
    { value: description.toc, prefix: '목차' },
  ];

  const descriptionText = descriptionEntries.reduce((builtDescription, currentEntry) => {
    if (builtDescription.length > targetLength) {
      return builtDescription;
    }

    if (!currentEntry.value) {
      return builtDescription;
    }

    const sanitizedEntry = stripAllTags(currentEntry.value);
    const prefixedEntry = currentEntry.prefix ? `<${currentEntry.prefix}> ${sanitizedEntry}` : sanitizedEntry;

    return builtDescription ? `${builtDescription} ${prefixedEntry}` : prefixedEntry;
  }, '');

  return descriptionText;
};

export const getPriceInfo = (
  book: SearchBookData,
): Partial<{
  rentalSellingPrice: number;
  rentalDiscountPercentage: number;
  purchaseSellingPrice: number;
  purchaseDiscountPercentage: number;
}> => {
  const genre = bookApiBookDataUtils.getGenre(book);

  // 북 API에는 시리즈 단권 가격 정보가 없기 때문에 시리즈 단권에 한해서 검색 API의 가격 정보를 참조한다.
  const bookPriceInfo = book.price_info;
  const seriesPriceInfo = book.searchPrices?.series;
  const price = book.searchPrices?.book?.normal?.price;

  // 체험판
  const isTrial = book.property?.is_trial;
  if (isTrial) {
    return { purchaseSellingPrice: 0 };
  }

  // 무료책
  if (!seriesPriceInfo?.normal && price === 0 && bookPriceInfo?.buy?.price === 0) {
    return { purchaseSellingPrice: 0 };
  }

  // 단권에 대해 쓸만한 가격 정보가 있는 경우
  if (bookPriceInfo && typeof price === 'number' && price !== 0) {
    const rentalSellingPrice =
      bookPriceInfo.rent?.price === 0 && seriesPriceInfo?.rent
        ? seriesPriceInfo.rent.min_nonzero_price
        : bookPriceInfo.rent?.price;

    const rentalDiscountPercentage =
      bookPriceInfo.rent?.discount_percentage === 100 ? 0 : bookPriceInfo.rent?.discount_percentage;

    const purchaseSellingPrice =
      seriesPriceInfo?.normal && genre !== 'general'
        ? Math.min(seriesPriceInfo.normal.min_nonzero_price, price)
        : price;

    const purchaseDiscountPercentage = bookPriceInfo.buy?.discount_percentage ?? 0;

    return { rentalSellingPrice, rentalDiscountPercentage, purchaseSellingPrice, purchaseDiscountPercentage };
  }

  // 시리즈에 대한 가격 정보만 있는 경우
  const rentalSellingPrice = seriesPriceInfo?.rent?.min_nonzero_price;
  const purchaseSellingPrice =
    seriesPriceInfo?.normal && seriesPriceInfo.normal.min_price !== 0
      ? Math.min(seriesPriceInfo.normal.min_nonzero_price, seriesPriceInfo.normal.min_price)
      : seriesPriceInfo?.normal?.min_nonzero_price;

  return { rentalSellingPrice, purchaseSellingPrice };
};

export const convertFromBookApiBookData = (book: BookApiBookData, searchBook: SearchBook): SearchBookData => {
  const {
    prices_info: pricesInfo,
    series_prices_info: seriesPricesInfo,
    category_name: categoryName,
    parent_category_name: parentCategoryName,
    parent_category_name2: parentCategoryName2,
  } = searchBook;

  let category;
  if (!parentCategoryName && !parentCategoryName2) {
    category = categoryName;
  } else if (parentCategoryName && !parentCategoryName2) {
    category = parentCategoryName;
  } else if (!parentCategoryName && parentCategoryName2) {
    category = parentCategoryName2;
  } else if (parentCategoryName && parentCategoryName === parentCategoryName2) {
    category = parentCategoryName;
  } else {
    category = `${parentCategoryName ?? ''}, ${parentCategoryName2 ?? ''}`;
  }

  const bookPrices = pricesInfo.reduce<SearchBookDataPrices>(
    (pricesInfoByType, priceInfo) => ({ ...pricesInfoByType, [priceInfo.type]: priceInfo }),
    {},
  );

  const seriesPrices = seriesPricesInfo.reduce<SearchBookDataSeriesPrices>(
    (pricesInfoByType, priceInfo) => ({ ...pricesInfoByType, [priceInfo.type]: priceInfo }),
    {},
  );

  return {
    ...book,
    categoryName: category ?? undefined,
    highlight: searchBook.highlight,
    searchTitle: searchBook.web_title_title,
    searchPrices: { book: bookPrices, series: seriesPrices },
    ratings: {
      count: searchBook.buyer_rating_count,
      score: searchBook.buyer_rating_score,
    },
  };
};

export const convertToRenderData = (book: SearchBookData): BookRenderData => {
  const priceInfo = getPriceInfo(book);
  const description = getDescription(book, 170);

  const renderData = bookApiBookDataUtils.convertToRenderData(book);
  renderData.title = book.searchTitle || renderData.title;
  renderData.metadata = {
    ...renderData.metadata,
    starRate: {
      count: book.ratings?.count,
      score: book.ratings?.score,
    },
    extra: {
      ...renderData.metadata?.extra,
      description,
      genre: book.categoryName,
      price: {
        rental: {
          minSellingPrice: priceInfo.rentalSellingPrice,
          discountPercentage: priceInfo.rentalDiscountPercentage,
        },

        purchase: {
          minSellingPrice: priceInfo.purchaseSellingPrice,
          discountPercentage: priceInfo.purchaseDiscountPercentage,
        },
      },
    },
  };

  return renderData;
};
