import { Query } from 'express-serve-static-core';
import qs from 'qs';

import { FEATURE_FLAG_KEYS, getFeatureFlagValueByKey } from '@/components/common/FeatureFlag';
import { SectionItemsResourceModel } from '@/models/backendsApi/v2/SectionItemsResource/SectionItemsResourceModel';
import { SectionItemsResourceError } from '@/models/backendsApi/v2/SectionItemsResource/SectionItemsResourceType';
import { makeCallAPIMethod } from '@/services/backendsApi/backendsApiService';
import { ServiceMethod } from '@/services/baseService';
import { searchParamsToParsedQs } from '@/utils/query';

import { SectionItemsResourceRequest } from './interfaces/SectionItemsResourceRequest';

export const sectionItemsResource: ServiceMethod<
  SectionItemsResourceRequest,
  SectionItemsResourceModel,
  SectionItemsResourceError
> = async (reqParams, req) => {
  const { endpoint } = reqParams.body;
  const apiURL = new URL(endpoint);
  const call = makeCallAPIMethod(
    !!getFeatureFlagValueByKey({
      featureFlag: req?.FeatureFlag || reqParams?.featureFlag,
      featureFlagKey: FEATURE_FLAG_KEYS.WEB_INTERNAL_BACKENDSAPI_REQUEST,
    }),
  )(SectionItemsResourceModel);

  return call(
    {
      method: 'GET',
      pathname: apiURL.pathname,
      params: { query: searchParamsToParsedQs(apiURL.searchParams) },
      paramsSerializer: (params: Query) => qs.stringify(params, { arrayFormat: 'repeat' }),

      through: {
        method: 'POST',
        pathname: '/v2/section-items/resource',
        params: { body: { endpoint } },
      },

      preferNonProxied: true,
    },
    req,
  );
};
