import { PaymentRequestModel } from '@/models/booksBackend/PaymentRequest/PaymentRequestModel';
import { PaymentRequestFailure } from '@/models/booksBackend/PaymentRequest/PaymentRequestType';
import { ServiceMethod, stringifyRequestParams } from '@/services/baseService';

import { makeCallAPIMethod } from '../booksBackendService';
import { PaymentRequestRequest } from './interfaces/PaymentRequestRequest';

const call = makeCallAPIMethod(PaymentRequestModel);

export const paymentRequest: ServiceMethod<PaymentRequestRequest, PaymentRequestModel, PaymentRequestFailure> = async (
  reqParams,
  req,
) =>
  call<PaymentRequestRequest, PaymentRequestFailure, PaymentRequestModel>(
    {
      method: 'POST',
      pathname: '/api/payment/request',
      params: { body: reqParams.body },
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      transformRequest: [stringifyRequestParams],
      through: {
        method: 'POST',
        pathname: '/api/payment/request',
        params: { body: reqParams.body },
      },
    },
    req,
  );
