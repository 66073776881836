import { FEATURE_FLAG_KEYS, getFeatureFlagValueByKey } from '@/components/common/FeatureFlag';
import { AccountParentalConsentRequiredDataModel } from '@/models/backendsApi/v1/AccountParentalConsentRequiredData/AccountParentalConsentRequiredDataModel';
import { ServiceMethod } from '@/services/baseService';

import { makeCallAPIMethod } from '../../backendsApiService';
import { AccountParentalConsentRequiredDataRequest } from './interfaces/AccountParentalConsentRequiredDataRequest';

export interface AccountParentalConsentRequiredDataError {
  success: false;
  message: string;
  data: null;
}

export const accountParentalConsentRequiredData: ServiceMethod<
  AccountParentalConsentRequiredDataRequest,
  AccountParentalConsentRequiredDataModel,
  AccountParentalConsentRequiredDataError
> = async (reqParams?, req?, _?) => {
  const call = makeCallAPIMethod(
    !!getFeatureFlagValueByKey({
      featureFlag: req?.FeatureFlag || reqParams?.featureFlag,
      featureFlagKey: FEATURE_FLAG_KEYS.WEB_INTERNAL_BACKENDSAPI_REQUEST,
    }),
  )(AccountParentalConsentRequiredDataModel);

  return call<
    AccountParentalConsentRequiredDataRequest,
    AccountParentalConsentRequiredDataError,
    AccountParentalConsentRequiredDataModel
  >(
    {
      method: 'GET',
      pathname: '/v1/account/parental-consent/required-data',
      preferNonProxied: true,
    },
    req,
  );
};
