import { AccountActionValidateResultModel } from '@/models/booksBackend/ValidateResultModel/AccountActionValidateResultModel';
import { ServiceMethod, stringifyRequestParams } from '@/services/baseService';

import { makeCallAPIMethod } from '../booksBackendService';
import { AccountActionIsCurrentPasswordRequest } from './interfaces/AccountActionIsCurrentPasswordRequest';

const call = makeCallAPIMethod(AccountActionValidateResultModel);

export const accountActionIsCurrentPassword: ServiceMethod<
  AccountActionIsCurrentPasswordRequest,
  AccountActionValidateResultModel
> = async (reqParams, req) =>
  call<AccountActionIsCurrentPasswordRequest, Error, AccountActionValidateResultModel>(
    {
      method: 'POST',
      pathname: '/account/action/is-current-password',
      preferNonProxied: true,
      params: { body: reqParams.body },
      transformRequest: [stringifyRequestParams],
    },
    req,
  );
