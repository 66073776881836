import { FEATURE_FLAG_KEYS, getFeatureFlagValueByKey } from '@/components/common/FeatureFlag';
import { AccountVerificationSendEmailModel } from '@/models/backendsApi/v1/AccountVerificationSendEmail/AccountVerificationSendEmailModel';
import { ServiceMethod } from '@/services/baseService';

import { makeCallAPIMethod } from '../../backendsApiService';
import { AccountVerificationSendEmailError } from './interfaces/accountVerificationSendEmailError';
import { AccountVerificationSendEmailRequest } from './interfaces/accountVerificationSendEmailRequest';

export const accountVerificationSendEmail: ServiceMethod<
  AccountVerificationSendEmailRequest,
  AccountVerificationSendEmailModel,
  AccountVerificationSendEmailError
> = async (reqParams, req) => {
  const call = makeCallAPIMethod(
    !!getFeatureFlagValueByKey({
      featureFlag: req?.FeatureFlag || reqParams?.featureFlag,
      featureFlagKey: FEATURE_FLAG_KEYS.WEB_INTERNAL_BACKENDSAPI_REQUEST,
    }),
  )(AccountVerificationSendEmailModel);

  return call<
    AccountVerificationSendEmailRequest,
    AccountVerificationSendEmailError,
    AccountVerificationSendEmailModel
  >(
    {
      method: 'POST',
      pathname: '/v1/account/verification/send-email',
      params: {
        body: reqParams.body,
      },
      preferNonProxied: true,
    },
    req,
  );
};
