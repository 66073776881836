import type { EventItem as AppEventItem, EventPurchaseItem as AppEventPurchaseItem } from '@ridi-web/inappbridge';

import { InAppBridge } from '@/utils/inappBridge';

import type { EventClientType, EventItem, EventPurchaseItem } from '..';

const mapToAppEventItem = (item: EventItem): AppEventItem => ({
  itemId: item.item_id,
  itemName: item.item_name,
  itemEpisodeId: item.item_episode_id,
  itemEpisodeName: item.item_episode_name,
  itemCategory: `${item.item_category}`,
  serviceType: item.service_type,
});

const mapToAppEventPurchaseItem = (item: EventPurchaseItem): AppEventPurchaseItem => ({
  ...mapToAppEventItem(item),
  parentCategory: item.item_parent_category,
  price: item.price,
  value: 'value' in item ? (item as EventPurchaseItem & { value: number }).value : 0,
});

export const InAppEventClient: EventClientType = {
  initialize() {},

  isInitialized() {
    return true;
  },

  sendInAppPageView({ userIdx, location, referrer } = {}) {
    InAppBridge.log.pageView({
      user_idx: userIdx,
      location,
      referrer,
    });
  },

  sendCustomEvent(screenName, action, target, params) {
    InAppBridge.log.event(screenName, action, target, params);
  },

  sendPurchase(transactionId, purchaseInfo) {
    InAppBridge.log.purchase({
      currency: purchaseInfo.currency,
      value: purchaseInfo.value,
      items: purchaseInfo.items.map(mapToAppEventPurchaseItem),
      transactionId,
    });
  },

  sendBeginCheckout(checkoutItems) {
    InAppBridge.log.beginCheckout({
      items: checkoutItems.map(mapToAppEventItem),
    });
  },
};
