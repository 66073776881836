import { GetService, makeServiceMethod } from '../baseService';

export const getService: GetService = ({ path = '/api/books-backend' } = {}) => ({
  host: process.env.NEXT_PUBLIC_BOOKS_BACKEND_API,
  routePrefix: undefined,
  through: {
    host: undefined,
    routePrefix: path,
  },
});

export const makeCallAPIMethod = makeServiceMethod(getService);

export const makeCallAPIMethodWithoutPrefix = makeServiceMethod(() => getService({ path: '' }));
