import { dehydrate, DehydratedState } from 'react-query';

import { setLoggedUserAction } from '@/features/global/auth/authSlice';
import {
  AppStore,
  ErrorPageProps,
  GetServerSidePropsWrapper,
  GetServerSidePropsWrapperContext,
  GetServerSidePropsWrapperOptions,
  State,
  wrapper as originalWrapper,
} from '@/features/store';
import { generateQueryClient } from '@/queries/base';
import { postCsrfToken } from '@/queries/globals/useCsrfToken';
import sendException from '@/utils/sentry/sendException';

type RootStore = AppStore<State>;

type PropsWithDehydrated = { props: { dehydratedState?: DehydratedState } };

const hasProps = (result: unknown): result is PropsWithDehydrated => (result as { props: Any }).props !== undefined;

export const isErrorPageProps = (props: unknown): props is ErrorPageProps =>
  (props as ErrorPageProps).error !== undefined;

const isWithCsrfToken = (options?: GetServerSidePropsWrapperOptions): options is { withCsrfToken: true } =>
  options?.withCsrfToken === true;

const getServerSidePropsWrapper: GetServerSidePropsWrapper<RootStore> = (callback, options) => async context => {
  const addFinishMetric = (context as GetServerSidePropsWrapperContext).req.addMetric?.('getServerSidePropsWrapper');
  const user = (context as GetServerSidePropsWrapperContext).req.User;
  // redux를 대체할 공유 react query client
  const sharedClient = generateQueryClient();

  try {
    let csrfToken = '';
    try {
      if (isWithCsrfToken(options)) {
        const result = await postCsrfToken({ req: (context as GetServerSidePropsWrapperContext).req });
        if (result.csrfToken) {
          csrfToken = result.csrfToken;
          sharedClient.setQueryData('globals/csrfToken', csrfToken);
        }
      }
    } catch {
      // csrf token을 가져오는 과정에서 에러가 발생하면 무시한다.
    }

    const result = await originalWrapper.getServerSideProps(store => {
      store.dispatch(setLoggedUserAction({ loggedUser: user || null }));

      return callback(store, sharedClient, { csrfToken });
    }, options)(context);

    if (hasProps(result) && isErrorPageProps(result.props)) {
      const { statusCode } = result.props.error;
      // eslint-disable-next-line no-param-reassign
      context.res.statusCode = statusCode;
    }

    if (hasProps(result)) {
      result.props.dehydratedState = dehydrate(sharedClient);
    }

    addFinishMetric?.();

    return result;
  } catch (err) {
    if (err instanceof Error) {
      sendException(err, {
        level: 'error',
        tags: {
          type: 'ServerSidePropsWrapper',
        },
      });
    }

    throw err;
  }
};

/**
 * 페이지 컴포넌트에서 실제로 사용할 wrapper 객체
 */
export const wrapper = {
  ...originalWrapper,
  getServerSideProps: getServerSidePropsWrapper,
};
