import {
  Array as RArray,
  Literal as RLiteral,
  Number as RNumber,
  Record as RRecord,
  Static as RStatic,
  String as RString,
  Union as RUnion,
} from 'runtypes';

export const PayObject = {
  PURCHASE: 2,
  RENTAL: 4,
  ENTIRE_PURCHASE: 7,
  ENTIRE_RENTAL: 8,
  POINTBACK_RENTAL: 16,
} as const;

export const RNonEntirePayObjectValue = RUnion(
  RLiteral(PayObject.PURCHASE),
  RLiteral(PayObject.RENTAL),
  RLiteral(PayObject.POINTBACK_RENTAL),
);
export type NonEntirePayObjectValue = RStatic<typeof RNonEntirePayObjectValue>;

export const REntirePayObjectValue = RUnion(RLiteral(PayObject.ENTIRE_PURCHASE), RLiteral(PayObject.ENTIRE_RENTAL));
export type EntirePayObjectValue = RStatic<typeof REntirePayObjectValue>;

export const RPayObjectValue = RUnion(RNonEntirePayObjectValue, REntirePayObjectValue);
export type PayObjectValue = RStatic<typeof RPayObjectValue>;

export const RPaymentRequestRequestItem = RUnion(
  RRecord({
    b_ids: RArray(RString),
    object: RNonEntirePayObjectValue,
  }),
  RRecord({
    series_id: RString,
    object: REntirePayObjectValue,
  }),
);
export type PaymentRequestRequestItem = RStatic<typeof RPaymentRequestRequestItem>;

const RPaymentRequestRequestBody = RPaymentRequestRequestItem.And(
  RRecord({
    use_point: RNumber,
    use_cash: RNumber,
    _token: RString,
    discount_coupon_id: RString.optional(),
    point_gift_card_coupon_id: RString.optional(),
  }),
);

export const RPaymentRequestRequest = RRecord({
  body: RPaymentRequestRequestBody,
});

export type PaymentRequestRequest = RStatic<typeof RPaymentRequestRequest>;
