import { CartCountModel } from '@/models/booksBackend/Cart/CartCountModel';
import { ServiceMethod } from '@/services/baseService';

import { makeCallAPIMethod } from '../booksBackendService';

const call = makeCallAPIMethod(CartCountModel);

export const cartCount: ServiceMethod<void, CartCountModel, void> = async (_, req) =>
  call<void, void, CartCountModel>(
    {
      method: 'GET',
      pathname: '/api/cart/count',
      preferNonProxied: true,
      timeout: 5000,
    },
    req,
  );
