import { AccountActionFindPasswordModel } from '@/models/booksBackend/FindPasswordModel/AccountActionFindPasswordModel';
import { ServiceMethod, stringifyRequestParams } from '@/services/baseService';

import { makeCallAPIMethod } from '../booksBackendService';
import { AccountActionFindPasswordRequest } from './interfaces/AccountActionFindPasswordRequest';

const call = makeCallAPIMethod(AccountActionFindPasswordModel);

export const accountActionFindPassword: ServiceMethod<
  AccountActionFindPasswordRequest,
  AccountActionFindPasswordModel
> = async reqParams =>
  call<AccountActionFindPasswordRequest, Error, AccountActionFindPasswordModel>({
    method: 'POST',
    pathname: '/account/action/find-password',
    params: { body: reqParams.body },
    transformRequest: [stringifyRequestParams],
    preferNonProxied: true,
  });
