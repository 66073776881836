import { useRouter } from 'next/router';
import { ReactNode, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { hydrateTemporaryAuthAction } from '@/features/global/actions';
import { isHydrateNeededSelector } from '@/features/global/isHydrateNeeded';
import { isRidiAppSelector } from '@/features/global/variables/variablesSlice';
import { useIsomorphicLayoutEffect } from '@/hooks/useIsomorphicLayoutEffect';
import { useLoggedUser } from '@/hooks/useLoggedUser';
import { getUId, initializeEventClient, sendInAppPageView, sendPageView, setFfid, setUId } from '@/utils/eventClient';
import { TestEventClient } from '@/utils/eventClient/clients/TestEventClient';
import { isBrowser } from '@/utils/isBrowser';
import sendException from '@/utils/sentry/sendException';
import { getStorageItemJSON, LOCAL_STORAGE_KEYS } from '@/utils/storage';

interface PageViewEventControllerProps {
  children: ReactNode;
  ffid?: string;
}

const BLACK_LIST_PAGE = ['/partials'];

const isBlocked = !isBrowser() || BLACK_LIST_PAGE.includes(window.__NEXT_DATA__.page);

const inAppPageViewTargets = ['/account/secede', '/account/leave', '/inapp/account', '/inapp/search', '/views'];

export const PageViewEventController = ({ children, ffid }: PageViewEventControllerProps): ReactJSX.Element => {
  const dispatch = useDispatch();
  const user = useLoggedUser();
  const isHydrateNeeded = useSelector(isHydrateNeededSelector);
  const router = useRouter();
  const isRidiApp = useSelector(isRidiAppSelector);

  // 웹뷰 pageview에서 CSR시 사용할 referrer를 저장
  const referrer = useRef('');
  useEffect(() => {
    if (!isBrowser() || !isRidiApp) {
      return;
    }

    if (!inAppPageViewTargets.some(target => router.asPath.includes(target))) {
      return;
    }

    sendInAppPageView({ userIdx: user?.idx, referrer: referrer.current || document.referrer });
    referrer.current = `${window.location.origin}${router.asPath}`;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRidiApp, router.asPath]);

  const isSentPageView = useRef(false);

  useIsomorphicLayoutEffect(() => {
    if (getStorageItemJSON('local', LOCAL_STORAGE_KEYS.DEBUG_EVENTCLIENT)) {
      initializeEventClient(TestEventClient);
    } else {
      initializeEventClient();
    }

    setFfid(ffid);
    if (isBlocked || isSentPageView.current) {
      return;
    }

    const eventClientUid = getUId();
    const { props, ...sentryPayload } = window.__NEXT_DATA__;
    if (isHydrateNeeded) {
      const { loggedUser: temporaryUser } = dispatch(hydrateTemporaryAuthAction()).payload;
      if (temporaryUser && eventClientUid === undefined) {
        setUId(temporaryUser.idx || null);

        if (temporaryUser.idx === 0) {
          sendException(new Error('Unexpected temp uidx 0'), { level: 'warning', extra: { payload: sentryPayload } });
        }
      }
    } else if (user && eventClientUid === undefined) {
      setUId(user.idx || null);

      if (user.idx === 0) {
        sendException(new Error('Unexpected uidx 0'), { level: 'warning', extra: { payload: sentryPayload } });
      }
    }

    if (!isRidiApp) {
      sendPageView();
    }
    isSentPageView.current = true;
  }, [isHydrateNeeded, user?.idx, user?.id]);

  useEffect(
    () => () => {
      isSentPageView.current = false;
    },
    [router.asPath],
  );

  return <>{children}</>;
};
