import { AnyAction, combineReducers } from '@reduxjs/toolkit';

import { fullSearchSlice, FullSearchState } from './fullSearch/fullSearchSlice';
import { instantSearchSlice, InstantSearchState } from './instantSearch/instantSearchSlice';

export interface SearchState {
  fullSearch: FullSearchState;
  instantSearch: InstantSearchState;
}

export const searchReducer = (state: SearchState | undefined, action: AnyAction): SearchState => {
  const combinedReducers = combineReducers({
    fullSearch: fullSearchSlice.reducer,
    instantSearch: instantSearchSlice.reducer,
  });

  return combinedReducers(state, action);
};

export type SearchReducerType = ReturnType<typeof searchReducer>;
