import type { FeatureFlagDurationValue } from './utils';

export const FEATURE_FLAG_KEYS = {
  // 기능 플래그
  DEAUTH_WATCHDOG: 'deauth-watchdog',
  SHIPPING_MANAGEMENT: 'web-shipping-management',

  // 피쳐 가드
  WEB_INTERNAL_BACKENDSAPI_REQUEST: 'web-internal-backendsapi-request-20240710',
  WEB_REALUSERMONITORING_20231221: 'web-realusermonitoring-20231221',
  WEB_RIDIPAY_20231013: 'web-ridipay-20231013',
  WEB_COOKIE_IN_NAVIGATION_20240318: 'web-cookie-in-navigation-20240318',
  WEB_INTEREST_BOOK_20240513: 'web-interest-book-20240513',
  DISCOVERY_EXP_RANKING_SECTION_20231201: 'discovery-exp-ranking-section-20231201',
  BOOKS_FRONTEND_DATADOG_RUM_TARGET_USERS: 'booksfrontend-datadog-rum-target-users-20240223',
  DISCOVERY_FANTASY_RENT_FRONTEND_20240523: 'discovery-fantasy-rent-frontend-20240523',
  WEB_SEARCH_RIGRID_MIGRATION_20240613: 'web-search-rigrid-migration-20240613',
  WEB_NAVIGATION_BAR_20240808: 'web-navigation-bar-20240808',
  WEB_NAVIGATION_BAR_TOGGLE_20240808: 'web-navigation-bar-toggle-20240808',
  WEB_OAKTREE_ONEDAY_PROMOTION_20240824: 'web-oaktree-oneday-promotion-20240824',
} as const;

export type FEATURE_FLAG_TYPES = {
  [FEATURE_FLAG_KEYS.DEAUTH_WATCHDOG]: boolean;
  [FEATURE_FLAG_KEYS.SHIPPING_MANAGEMENT]: FeatureFlagDurationValue & { promotionUrl: string };

  [FEATURE_FLAG_KEYS.WEB_INTERNAL_BACKENDSAPI_REQUEST]: boolean;
  [FEATURE_FLAG_KEYS.WEB_REALUSERMONITORING_20231221]: boolean;
  [FEATURE_FLAG_KEYS.WEB_RIDIPAY_20231013]: boolean;
  [FEATURE_FLAG_KEYS.WEB_COOKIE_IN_NAVIGATION_20240318]: boolean;
  [FEATURE_FLAG_KEYS.WEB_INTEREST_BOOK_20240513]: boolean;
  [FEATURE_FLAG_KEYS.DISCOVERY_EXP_RANKING_SECTION_20231201]: boolean;
  [FEATURE_FLAG_KEYS.BOOKS_FRONTEND_DATADOG_RUM_TARGET_USERS]: number[];
  [FEATURE_FLAG_KEYS.DISCOVERY_FANTASY_RENT_FRONTEND_20240523]: boolean;
  [FEATURE_FLAG_KEYS.WEB_SEARCH_RIGRID_MIGRATION_20240613]: boolean;
  [FEATURE_FLAG_KEYS.WEB_NAVIGATION_BAR_20240808]: boolean;
  [FEATURE_FLAG_KEYS.WEB_NAVIGATION_BAR_TOGGLE_20240808]: boolean;
  [FEATURE_FLAG_KEYS.WEB_OAKTREE_ONEDAY_PROMOTION_20240824]: boolean;
};
