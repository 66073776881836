import { datadogRum, RumInitConfiguration } from '@datadog/browser-rum';
import axios from 'axios';

import { getShortRunMode } from '../env';
import { isBrowser } from '../isBrowser';
import { ExceptionOption } from '../sentry/types';

const options: RumInitConfiguration | null =
  process.env.NEXT_PUBLIC_RUM_APPLICATION_ID && process.env.NEXT_PUBLIC_RUM_CLIENT_TOKEN
    ? {
        applicationId: process.env.NEXT_PUBLIC_RUM_APPLICATION_ID,
        clientToken: process.env.NEXT_PUBLIC_RUM_CLIENT_TOKEN,
        site: 'datadoghq.com',
        service: 'web--ridibooks',
        env: getShortRunMode(),
        version: process.env.RELEASE_ID,
        sessionSampleRate: 100, // startSessionReplayRecordingManually로 수동으로 녹화를 시작하므로 100%로 설정. 실제로는 SESSION_SAMPLE_RATE를 사용
        sessionReplaySampleRate: 100,
        startSessionReplayRecordingManually: true,
        trackResources: true,
        trackLongTasks: true,
        trackUserInteractions: true,
        defaultPrivacyLevel: 'mask-user-input',
        enableExperimentalFeatures: ['feature_flags'],
      }
    : null;

const isRUMInitialized = () => window.DD_RUM.getInitConfiguration() !== undefined;
const isRUMRecording = () => window.DD_RUM.getInternalContext() !== undefined;

// 리그리드 작품홈에서 RUM 녹화가 여러 번 일어나는 문제를 임시방편으로 수정하기 위해 0으로 설정함. 0으로 두어서 녹화가 일어나지 않도록 함. 추후 0.3으로 원복할 예정
const SESSION_SAMPLE_RATE = 0;

export const initDataDogRum = () => {
  if (process.env.RUN_MODE !== 'production' && options && SESSION_SAMPLE_RATE !== 0) {
    datadogRum.init(options);
    if (Math.random() < SESSION_SAMPLE_RATE) {
      datadogRum.startSessionReplayRecording();
    }
  }
};

interface InitDataDogRumForUserSendSentryParams {
  level: ExceptionOption['level'];
  eventId: string;
  errorMessage: string;
}

export const initDataDogRumForUserSendSentry = ({
  level,
  eventId,
  errorMessage,
}: InitDataDogRumForUserSendSentryParams) => {
  if (isBrowser() && process.env.RUN_MODE !== 'production') {
    if (options && !isRUMInitialized()) {
      datadogRum.init({ ...options, sessionSampleRate: 100 });
    }
    const isAlreadyRecording = isRUMRecording();
    if (!isAlreadyRecording) {
      datadogRum.startSessionReplayRecording();
    }

    const userInfo = datadogRum.getUser();
    const idx = userInfo?.idx as number | undefined;
    if (idx) {
      axios.post('/api/labs/send-slack-for-rum-by-sentry', {
        uIdx: idx,
        eventId,
        errorMessage,
        level,
        isAlreadyRecording,
      });
    }
  }
};

export const initDataDogRumForTargetUsers = () => {
  if (isBrowser()) {
    const LAST_RUN_TIME_KEY = 'lastExecutionTime';
    const COOL_TIME = 300000; // 5분
    const currentTime = Date.now();
    const lastExecutionTime = parseInt(localStorage.getItem(LAST_RUN_TIME_KEY) || '0', 10);

    if (currentTime - lastExecutionTime < COOL_TIME) {
      return;
    }

    if (options && !isRUMInitialized()) {
      datadogRum.init({ ...options, sessionSampleRate: 100 });
    }
    const isAlreadyRecording = isRUMRecording();
    if (!isAlreadyRecording) {
      datadogRum.startSessionReplayRecording();
    }
    const userInfo = datadogRum.getUser();
    const idx = userInfo?.idx as number | undefined;
    if (idx) {
      axios.post('/api/labs/send-slack-for-rum-by-target', { uIdx: idx, isAlreadyRecording });
      localStorage.setItem(LAST_RUN_TIME_KEY, currentTime.toString());
    }
  }
};
