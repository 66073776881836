import { PaymentRouteBookModel } from '@/models/booksBackend/PaymentRouteBook/PaymentRouteBookModel';
import { ServiceMethod } from '@/services/baseService';

import { makeCallAPIMethod } from '../booksBackendService';
import { PaymentRouteBookRequest } from './interfaces/PaymentRouteBookRequest';

const call = makeCallAPIMethod(PaymentRouteBookModel);

export interface PaymentRouteBookError {
  code: string;
  message: string;
}

export const paymentRouteBook: ServiceMethod<
  PaymentRouteBookRequest,
  PaymentRouteBookModel,
  PaymentRouteBookError
> = async (reqParams, req) =>
  call<void, PaymentRouteBookError, PaymentRouteBookModel>(
    {
      method: 'GET',
      pathname: '/api/payment/route/book',
      params: { query: reqParams.query },
      preferNonProxied: true,
    },
    req,
  );
