import { NavigationModel } from '@/models/backendsApi/v2/Navigation/NavigationModel';
import { getCacheKey, makeServiceAction } from '@/services/baseService';

import { makeLegacyCallAPIMethod } from '../../backendsApiService';

export type NavigationError = {
  success: false;
  message: string;
  data: null;
};

const makeCallMethod = () => makeLegacyCallAPIMethod(NavigationModel);
const makeCacheKey = () => getCacheKey('backendsApi', 'v2/navigation', {});

export const fetchNavigation = makeServiceAction<void, NavigationError, NavigationModel>(makeCallMethod(), () => ({
  method: 'GET',
  pathname: '/v2/navigation/web',
  preferNonProxied: true,
}))(NavigationModel, makeCacheKey);
