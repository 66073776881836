import { datadogRum } from '@datadog/browser-rum';
import { reconstate } from '@ridi-web/reconstate';

import { FeatureFlagType } from '@/models/backendsApi/v2/FeatureFlag/FeatureFlagType';

import { FEATURE_FLAG_KEYS, FEATURE_FLAG_TYPES } from './constants';

export type FeatureFlagValue<FlagKey extends keyof FEATURE_FLAG_TYPES> = FEATURE_FLAG_TYPES[FlagKey];

export const getFeatureFlagValueByKey = <FlagKey extends keyof FEATURE_FLAG_TYPES>({
  featureFlag = [{}] as FeatureFlagType,
  featureFlagKey,
}: {
  featureFlag?: FeatureFlagType;
  featureFlagKey: FlagKey;
}) => {
  const featureFlagValue = featureFlag?.find(flag => flag.flagKey === featureFlagKey)?.flagValue as
    | FeatureFlagValue<FlagKey>
    | undefined;

  // datadog rum 이 활성화된 사용자에게만 feature flag 정보를 rum 에 전달한다.
  if (featureFlag?.find(flag => flag.flagKey === FEATURE_FLAG_KEYS.WEB_REALUSERMONITORING_20231221)?.flagValue) {
    datadogRum?.addFeatureFlagEvaluation(featureFlagKey, featureFlagValue);
  }
  return featureFlagValue;
};

const useFeatureFlagProvider = ({ featureFlag = [{}] as FeatureFlagType }: { featureFlag?: FeatureFlagType }) => ({
  featureFlag,
  featureFlagValueByKey: <FlagKey extends keyof FEATURE_FLAG_TYPES>(featureFlagKey: FlagKey) =>
    getFeatureFlagValueByKey({ featureFlag, featureFlagKey }),
});

const draft = reconstate(useFeatureFlagProvider);

export const useFeatureFlag = draft.add(({ featureFlag }) => featureFlag);
export const useFeatureFlagValueByKey = draft.add(({ featureFlagValueByKey }) => featureFlagValueByKey);

export const FeatureFlagProvider = draft.finalize();
