import { EventClient } from '@ridi/event-client';

import type { EventClientType } from '..';
import { getDeviceType } from '../getDeviceType';

let eventClient: EventClient | null = null;

export const RIDIEventClient: EventClientType = {
  initialize() {
    const deviceType = getDeviceType(window.navigator.userAgent);
    if (typeof process.env.NEXT_PUBLIC_GTM_TRACKING_ID === 'undefined') {
      return;
    }

    eventClient = new EventClient({
      deviceType,
      trackingId: process.env.NEXT_PUBLIC_GTM_TRACKING_ID,
      debug: false,
      autoPageView: false,
    });
  },

  isInitialized() {
    return !!eventClient?.isInitialized();
  },

  getUId() {
    return eventClient?.uId;
  },

  setFfid(ffid?: string) {
    return eventClient?.setFfid(ffid);
  },

  setUId(uIdx?: number | null) {
    eventClient?.setUId(uIdx);
  },

  sendPageView() {
    eventClient?.sendPageView();
  },

  sendCustomEvent(screenName, action, target, params) {
    if (window.appboy !== undefined) {
      eventClient?.sendCustomEvent(`${screenName}_${action}_${target}`, {
        ...params,
        path: window.location.origin + window.location.pathname,
      });
    } else {
      let count = 0;
      const maxCount = 3;
      const retrySendEvent = setInterval(() => {
        count += 1;
        if (count > maxCount) {
          clearInterval(retrySendEvent);
          return;
        }
        if (window.appboy !== undefined) {
          eventClient?.sendCustomEvent(`${screenName}_${action}_${target}`, {
            ...params,
            path: window.location.origin + window.location.pathname,
          });
          clearInterval(retrySendEvent);
        }
      }, 1000);
    }
  },

  sendBeginCheckout(items) {
    eventClient?.sendBeginCheckout(items);
  },

  sendLogin() {
    eventClient?.sendLogin();
  },

  sendPurchase(transactionId, purchaseInfo) {
    eventClient?.sendPurchase(transactionId, { ...purchaseInfo });
  },

  sendPurchaseChargeAndPay(transactionId, purchaseInfo) {
    eventClient?.sendPurchaseChargeAndPay(transactionId, { ...purchaseInfo });
  },

  sendSignup() {
    eventClient?.sendSignUp();
    if (window.appboy) {
      window.appboy.logCustomEvent('sign_up');
    }
  },

  sendSignupInfoCompleted() {
    eventClient?.sendCustomEvent('sign_up_info_completed', {});
  },

  sendScrollEvent(screenName, target, params) {
    eventClient?.sendCustomEvent(`${screenName}_scroll_${target}`, {
      ...params,
      path: window.location.origin + window.location.pathname,
    });
  },

  sendBrazeCustomEvent(eventName: string) {
    if (window.appboy) {
      window.appboy.logCustomEvent(eventName);
    } else {
      let count = 0;
      const maxCount = 3;
      const retrySendEvent = setInterval(() => {
        count += 1;
        if (count > maxCount) {
          clearInterval(retrySendEvent);
          return;
        }
        if (window.appboy) {
          window.appboy.logCustomEvent(eventName);
          clearInterval(retrySendEvent);
        }
      }, 1000);
    }
  },
};
