import { FEATURE_FLAG_KEYS, getFeatureFlagValueByKey } from '@/components/common/FeatureFlag';
import { CheckoutModel } from '@/models/backendsApi/v1/Checkout/CheckoutModel';
import { ServiceMethod } from '@/services/baseService';

import { makeCallAPIMethod } from '../../backendsApiService';
import { CheckoutRequest } from './interfaces/CheckoutRequest';

export interface CheckoutError {
  success: false;
  message: string;
  data: null;
}

export const checkout: ServiceMethod<CheckoutRequest, CheckoutModel, CheckoutError> = async (reqParams, req) => {
  const call = makeCallAPIMethod(
    !!getFeatureFlagValueByKey({
      featureFlag: req?.FeatureFlag || reqParams?.featureFlag,
      featureFlagKey: FEATURE_FLAG_KEYS.WEB_INTERNAL_BACKENDSAPI_REQUEST,
    }),
  )(CheckoutModel);
  return call<CheckoutRequest, CheckoutError, CheckoutModel>(
    {
      method: 'POST',
      pathname: '/v1/checkout',
      params: { body: reqParams.body },
      preferNonProxied: true,
    },
    req,
  );
};
